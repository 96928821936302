/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, SeparateLineWrap, Title, Image, Divider, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Candela"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--60" style={{"paddingBottom":0}} name={"pgrighgh2bs"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0,"marginTop":0}} name={"g36nbh5sc7c"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"paddingBottom":0}} content={"<span style=\"color: var(--color-custom-2);\"><a href=\"/fotogalerie#1\">&lt; champions</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingBottom":0,"paddingTop":0,"marginTop":0}} name={"bjbqm6rdwq9"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":73}} name={"j6by5unhn"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingTop":35,"marginTop":16}} columns={"1"}>
            
            <ColumnWrapper style={{"marginTop":21}}>
              
              <Title className="title-box fs--62 lh--12" content={"Vincent Candela\n"}>
              </Title>

              <Image style={{"maxWidth":306,"marginTop":12}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} alt={""} src={"https://cdn.swbpg.com/o/40863/56d66d507d6540d4b922f16483de0751.svg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" layout={"l8"} style={{"paddingTop":0}} name={"301gscqctk8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/8d51b7ec79ee4f359e11f94a98094942_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/8d51b7ec79ee4f359e11f94a98094942_s=350x_.png 350w"} style={{"maxWidth":180}} lightbox={false} use={"page"} href={"/candela-negroamaro-merlot-salento-igp"}>
              </Image>

              <Divider style={{"paddingBottom":0}}>
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--02" style={{"marginTop":0}} content={"<a href=\"/candela-negroamaro-merlot-salento-igp\">Negroamaro Merlot Salento I.G.P.</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":0}} content={"na dotaz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/9c6eb6377d4045a59b2a8a5a0b97837d_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/9c6eb6377d4045a59b2a8a5a0b97837d_s=350x_.png 350w"} style={{"maxWidth":180}} lightbox={false} use={"page"} href={"/candela-primitivo-rosato-igp"}>
              </Image>

              <Divider style={{"paddingBottom":0}}>
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--02" style={{"marginTop":0}} content={"<a href=\"/candela-primitivo-rosato-igp\">Primitivo Rosato I.G.P.<br></a><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":0}} content={"na dotaz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/544ac2dd147b45f196576306ecdccb4e_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/544ac2dd147b45f196576306ecdccb4e_s=350x_.png 350w"} style={{"maxWidth":180}} lightbox={false} use={"page"} href={"/candela-verdeca-puglia-bianco-igp"}>
              </Image>

              <Divider style={{"paddingBottom":0}}>
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--02" style={{"marginTop":0}} content={"<a href=\"/candela-verdeca-puglia-bianco-igp\">Verdeca Puglia Bianco I.G.P.</a><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":0}} content={"na dotaz"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"uoc8b542kz8"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}