/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, YouTube, SeparateLine, SeparateLineWrap, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Historie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"24e3tymmgh7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":31}}>
              
              <Title className="title-box" content={"jak <span style=\"font-style: italic;\">vznikla</span> vína legend"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"„Víno legend“ představuje jedinečnou fúzi hlubokých hodnot, jako je přátelství, radost ze života a vášeň pro víno, které spojuje tým velkých šampionů. <br>Fabio Cordella, zakladatel tohoto projektu, je mužem sportu a potomkem historické rodiny vinařů, která od roku 1911 pěstuje vynikající vína na vinicích v Italském Salentu.<br><br>Bylo jeho přáním spojit tyto dva světy, které jsou na první pohled odlišné, ale zároveň si velmi blízké, a tím se zrodil jedinečný příběh The Wine of The Champions. Každé víno odráží osobnost svého šampiona, protože každý z nich spolupracoval s enology, aby vytvořil víno, které nejlépe odpovídá jeho charakteru a chuťovým preferencím.\n\nZačala nová hra, která se neodehrává na fotbalovém hřišti, ale na stolech milovníků vína po celém světě.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}} name={"f9ucpierste"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"mr8SEpxWvTM"} modestbranding={false} controls={false} ivLoadPolicy={false} fs={false} loop={true} autoplay={true}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"bo0hb8gs48q"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}