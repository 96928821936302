/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, SeparateLineWrap, Image, Divider, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Seznam dostupných vín"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pt--80" style={{"paddingBottom":25}} name={"lna7ukh7gvr"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"aktuální <span style=\"font-style: italic;\">nabídka</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60" style={{"paddingTop":0}} name={"igtz1fot69a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":786}} content={"Vítejte ve světě prestižních vín, která stojí na vrcholu úspěchu.&nbsp;<br>&nbsp;Prozkoumejte naši\nexkluzivní kolekci a objevte vína, která vám přinesou nezapomenutelný\nzážitek a zanechají ve vás vzpomínku na vítězství."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s5" style={{"paddingBottom":0,"paddingTop":0}} name={"rs7n2hfxb6"} anim={"5"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"růžová&nbsp;<span style=\"font-style: italic;\">vína</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"paddingTop":0}} name={"fj3mqtdorvo"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60" style={{"paddingTop":39}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/e210422457c54aa98f0ac6ebcd3470a5_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/e210422457c54aa98f0ac6ebcd3470a5_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho-negroamaro-rosato-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/ronaldinho-negroamaro-rosato-igp\">Ronaldinho </a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Negroamaro Rosato I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/96219f57e1ba4921badfee42a808bcf1_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/96219f57e1ba4921badfee42a808bcf1_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/materazzi-salento-igp-primitivo-rosato"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/materazzi-salento-igp-primitivo-rosato\">MATERAZZI</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento I.G.P. Primitivo Rosato"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/0f1ac82bda194141828f8e4e7a71d561_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/0f1ac82bda194141828f8e4e7a71d561_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/sneijder-negroamaro-rose-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/sneijder-negroamaro-rose-igp\">SNEIJDER</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Negroamaro Rosé I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s5" style={{"paddingBottom":0,"paddingTop":0}} name={"rs7n2hfxb6"} anim={"5"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"bílá&nbsp;<span style=\"font-style: italic;\">vína</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"paddingTop":0}} name={"7u1uc1x2vy8"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":39,"paddingBottom":0}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/bb443393f057472184eeb6663b7d023e_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/bb443393f057472184eeb6663b7d023e_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho-salento-chardonnay-salento-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/ronaldinho-salento-chardonnay-salento-igp\">Ronaldinho </a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Chardonnay Salento I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/e7fa3520e03d4e0c82cf278a6d883f90_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/e7fa3520e03d4e0c82cf278a6d883f90_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/materazzi-salento-chardonnay-barricato-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/materazzi-salento-chardonnay-barricato-igp\">MATERAZZI</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento Chardonnay Barricato I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/9b344996f2a042d0a92c9f629518159d_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/9b344996f2a042d0a92c9f629518159d_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/carlos-bianco-salento-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/carlos-bianco-salento-igp\">CARLOS</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Bianco Salento I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/713418cadcf44dfb925560e3919dff56_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/713418cadcf44dfb925560e3919dff56_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/terry-chardonnay-igp-salento"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/terry-chardonnay-igp-salento\">TERRY</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Chardonnay I.G.P. Salento"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/5a218f36e54d40e6879a077c9c60f235_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":139}} srcSet={"https://cdn.swbpg.com/t/40863/5a218f36e54d40e6879a077c9c60f235_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/5a218f36e54d40e6879a077c9c60f235_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/jankulovski-rosso-salento-bianco-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/jankulovski-rosso-salento-bianco-igp\">JANKULOVSKI</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento Bianco I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s5" style={{"paddingBottom":0,"paddingTop":0}} name={"rs7n2hfxb6"} anim={"5"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"červená&nbsp;<span style=\"font-style: italic;\">vína</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"paddingTop":0}} name={"t19gb78plfk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":39,"paddingBottom":0}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b9608eb5d644491499da3dcdbcb7c77c_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/b9608eb5d644491499da3dcdbcb7c77c_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/jankulovski-rosso-salento-igp-malvasia-merlot"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/jankulovski-rosso-salento-igp-malvasia-merlot\">JANKULOVSKI </a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Rosso Salento I.G.P. Malvasia Merlot"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b059a0ade3694f83bb28d23a03c7d33a_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/b059a0ade3694f83bb28d23a03c7d33a_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/carlos-negroamaro-salento-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/carlos-negroamaro-salento-igp\">CARLOS</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Negroamaro Salento I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/8172986c879a45aba7bb326dda01036c_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/8172986c879a45aba7bb326dda01036c_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho-salento-primitivo-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/ronaldinho-salento-primitivo-igp\">Ronaldinho</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento Primitivo I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b1c568c835bd4505be21324351b5c604_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/b1c568c835bd4505be21324351b5c604_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/sneijder-salento-rosso-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/sneijder-salento-rosso-igp\">SNEIJDER</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento Rosso I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/fe94702b1f994cd0950a6606035560be_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/fe94702b1f994cd0950a6606035560be_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/terry-salice-salentino-dop"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/terry-salice-salentino-dop\">TERRY</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salice Salentino D.O.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/040aac9ff10044f0847a4d66c39a42a7_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/040aac9ff10044f0847a4d66c39a42a7_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/cafu-salento-rosso-primitivo-malvasia-nera-igp"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/cafu-salento-rosso-primitivo-malvasia-nera-igp\">CAFU</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Salento Rosso Primitivo Malvasia Nera I.G.P."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/09b8a7c55dd746fe8d3c587b62f101e7_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/09b8a7c55dd746fe8d3c587b62f101e7_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/materazzi-copertino-dop-rosso"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/materazzi-copertino-dop-rosso\">MATERAZZI</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Copertino D.O.P. Rosso"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s5" style={{"paddingBottom":0,"paddingTop":0}} name={"rs7n2hfxb6"} anim={"5"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"šumivá <span style=\"font-style: italic;\">vína</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"paddingTop":0}} name={"f5qavfbch3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60" style={{"paddingTop":39}} layout={"l8"} name={"tt8erys6vrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/5b11f408eed7433a8b5cb17b7dc7badd_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":175}} srcSet={"https://cdn.swbpg.com/t/40863/5b11f408eed7433a8b5cb17b7dc7badd_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho-prosecco-doc"}>
              </Image>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box swpf--uppercase ls--0" style={{"marginTop":0}} content={"<a href=\"/ronaldinho-prosecco-doc\">Ronaldinho&nbsp;</a>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":4}} content={"Prosecco - DOC"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" style={{"paddingBottom":0}} name={"8fn9lrke6pc"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}