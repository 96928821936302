/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, SeparateLineWrap, Image, Subtitle, Title, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"víno Frey - Pinot Grigio I.G.P. Puglia"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"[[UNIsectionname12]]"} style={{"paddingBottom":0,"paddingTop":152}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0,"marginTop":32}} name={"g36nbh5sc7c"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"paddingBottom":0}} content={"<a href=\"/frey\">&lt; Frey </a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"marginTop":0,"paddingTop":0}} name={"rzzksog7hkn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"sdh6u0twq"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":1607,"paddingRight":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":319}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/40863/6116ae58ffaf4e69a94ae63606e23d46_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/6116ae58ffaf4e69a94ae63606e23d46_s=350x_.png"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: var(--color-custom-2);\">Frey</span><br>"}>
              </Subtitle>

              <Title className="title-box lh--12" content={"Pinot Grigio I.G.P. Puglia\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":767}} content={"Víno vyrobené z ručně sbíraných hroznů Pinot Grigio, pěstovaných na špalíru Guyot, zrající přibližně 18 měsíců v podzemních tancích, které podporují vyniknutí aromatických vůní. Má jasnou žlutou slámovou barvu a je charakteristické jemnou, ovocnou vůní. Chuť je svěží a lehká, dobře vyvážená mezi kyselinkou a hladkostí.\n\n\n\n\n\n\n"}>
              </Text>

              <Button className="btn-box" content={"objednat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ljodpetnirn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}