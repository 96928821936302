/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, SeparateLine, SeparateLineWrap, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Champions"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim2 --anim-s5 --center pt--80" style={{"paddingBottom":0,"marginBottom":48}} name={"vl913cfl2w"} anim={"2"} animS={"5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center lh--14" content={"<span style=\"font-style: italic;\">Vína</span>&nbsp; legend<br>"}>
              </Title>

              <Title className="title-box title-box--center ls--0" style={{"marginTop":8}} content={"KOMPLETNÍ NABÍDKA"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":11}} layout={"l9"} name={"x5p9iub888o"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":"","paddingBottom":123,"marginTop":44}}>
              
              <Image alt={"champion"} src={"https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=1400x_.png 1400w, https://cdn.swbpg.com/t/40863/c0037142694b4130b2289939f4779f82_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":37,"paddingTop":0}} layout={"l9"} name={"1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/5862811b1974428c9dc9d8d9d1b94020_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/5862811b1974428c9dc9d8d9d1b94020_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/jankulovski"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/jankulovski\">JANKULOVSKI</a>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/46607d4cd0ab41a7ad689567ce8d9dff_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/46607d4cd0ab41a7ad689567ce8d9dff_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/sneijder"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/sneijder\">SNEIJDER</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/aad541f94f2446499ba21cd5f83172dc_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/aad541f94f2446499ba21cd5f83172dc_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/ronaldinho\">RONALDINHO</a>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/0613e10850fe4cf687f02ca9a81abf97_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/0613e10850fe4cf687f02ca9a81abf97_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/0613e10850fe4cf687f02ca9a81abf97_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/0613e10850fe4cf687f02ca9a81abf97_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/0613e10850fe4cf687f02ca9a81abf97_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/carlos"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/carlos\">CARLOS</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/1478eee66b1e46c3a9a23ffacd980c30_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/1478eee66b1e46c3a9a23ffacd980c30_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/materazzi"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/materazzi\">MATERAZZI</a>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/65ac87fae41c4e498709bed4510fa8ad_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/65ac87fae41c4e498709bed4510fa8ad_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/terry"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/terry\">TERRY</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":37,"paddingTop":0}} layout={"l9"} name={"1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/cafu"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/cafu\">CAFU</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/cac2f848ae034211b2e0f1cd089a6483_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/cac2f848ae034211b2e0f1cd089a6483_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/cac2f848ae034211b2e0f1cd089a6483_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/cac2f848ae034211b2e0f1cd089a6483_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/amauri"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/amauri\">AMAURI</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/29b247feca1d472b8b6c456cebecbd50_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/29b247feca1d472b8b6c456cebecbd50_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/29b247feca1d472b8b6c456cebecbd50_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/29b247feca1d472b8b6c456cebecbd50_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/lugano"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/lugano\">LUGANO</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":37,"paddingTop":0}} layout={"l9"} name={"1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/chevanton"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/chevanton\" style=\"\">CHEVANTON</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/candela"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/candela\">CANDELA</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/kuranyi"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/kuranyi\">KURANYI</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":23,"paddingBottom":48}} layout={"l9"} name={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/cesar"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/cesar\">CESAR</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/e9b750b965d947cb92d25077f21e093f_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/e9b750b965d947cb92d25077f21e093f_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/e9b750b965d947cb92d25077f21e093f_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/e9b750b965d947cb92d25077f21e093f_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/e9b750b965d947cb92d25077f21e093f_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/ochoa"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/ochoa\">OCHOA</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/e87e06b49c6e4c969fe3f56b889a97a2_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/e87e06b49c6e4c969fe3f56b889a97a2_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/e87e06b49c6e4c969fe3f56b889a97a2_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/galliani"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/galliani\">GALLIANI</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":7}} layout={"l9"} name={"3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/1918fb2d84e5482782b565e24b8021c0_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/1918fb2d84e5482782b565e24b8021c0_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/frey"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/frey\">FREY</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/zamorano"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/zamorano\">ZAMORANO</a>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/amoroso"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--0 lh--18 mt--06" content={"<a href=\"/amoroso\" style=\"\">AMOROSO</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"6ozq5b7vi86"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}