/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, SeparateLineWrap, Image, Subtitle, Title, Divider, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"víno Jankulovski -Jankulovski QV30"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"[[UNIsectionname12]]"} style={{"paddingBottom":0,"paddingTop":152}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0,"marginTop":32}} name={"g36nbh5sc7c"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"paddingBottom":0}} content={"<a href=\"/jankulovski\">&lt;&nbsp;Jankulovski</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" style={{"marginTop":0,"paddingTop":0}} name={"rzzksog7hkn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"sdh6u0twq"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":1535}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":394}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/40863/76fa168d0eb7462091584df4324703e0_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/76fa168d0eb7462091584df4324703e0_s=350x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: var(--color-custom-2);\">Jankulovski</span><br>"}>
              </Subtitle>

              <Title className="title-box lh--12" content={"Jankulovski QV30\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":677}} content={"Perfektní spojení designu a technologie dalo vzniknout prvnímu chlazenému obrazu na světě, jedinečnému kusu nábytku, ve kterém jsou elegantně vystaveny a uchovávány ty nejvýznamnější vína. <br><br>Tento mimořádný předmět, upravený pro tuto příležitost, je součástí kolekce „The Wine of The Champions“, která je poctou velkým sportovním šampionům, umělecky ztvárněné s jemnými a jedinečnými personalizacemi. Vnitřní panel je vyroben z elegantní dekorované eko-kůže, zatímco vnější rám je zdoben bas-reliéfy, které definují jeho tvar a jedinečnost. <br><br>Limitovaná edice činí každý kus exkluzivním.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"653,-"}>
              </Subtitle>

              <Divider >
              </Divider>

              <Button className="btn-box" content={"objednat"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s5 pb--60" style={{"paddingTop":55}} layout={"l8"} name={"qyn5y13p48p"} animS={"5"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/40863/f357243f429a443eb3ca8e957983f673_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/40863/1e4c476979a94d7b8161d09d8890089c_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/40863/c2f3d013f1f54208b4ceff7c20f38f1d_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ljodpetnirn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 773 583 707<br>info@</span>vinalegend.cz"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/thewineofthechampionsczech/"} url={"https://www.instagram.com/thewineofthechampionsczech/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}